import { initializeApp } from "firebase/app";
import { initializeFirestore, CACHE_SIZE_UNLIMITED } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getMessaging } from "firebase/messaging";

/*
const devConfig = {
  apiKey: "AIzaSyBbNZJmvQ0skScnAVWuQxrrTMXaZw0eYQY",
  authDomain: "logicians-ghana.firebaseapp.com",
  databaseURL: "https://logicians-ghana.firebaseio.com",
  projectId: "logicians-ghana",
  storageBucket: "logicians-ghana.appspot.com",
  messagingSenderId: "47563389294",
  appId: "1:47563389294:web:4c9fca57feafc2cd9aa97f",
  measurementId: "G-36M8NZB5BP",
};
*/


const productionConfig = {
  apiKey: "AIzaSyC6wA2dqy_SmSOJvgxTe59lrxOcVAj-6qo",
  authDomain: "logic-schools-x.firebaseapp.com",
  projectId: "logic-schools-x",
  storageBucket: "logic-schools-x.appspot.com",
  messagingSenderId: "816226170318",
  appId: "1:816226170318:web:2cb321e4434466f308a79f",
  measurementId: "G-DHJNBVNMZG",
};

const config =
  process.env.NODE_ENV === "production" ? productionConfig : productionConfig; // change this to devConfig upon expansion.

export const app = initializeApp(config);

export const auth = getAuth(app);

export const messaging = getMessaging(app)

export const db = initializeFirestore(app, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,
});

export const storage = getStorage(app);

// export const functions = getFunctions(app);

import { HiCheckCircle } from "react-icons/hi";
import { BiSolidMessageRoundedError } from "react-icons/bi";
import { RiCloseCircleFill } from "react-icons/ri";
import { motion } from "framer-motion";
export const Feedback = ({ type, message, setOpen }) => (
  <div
    className={`fixed rounded-md shadow-md z-50 ${
      type === "success" ? "bg-green-500" : "bg-red-500"
    } flex items-center slide-up p-3 w-72`}
  >
    <div className="flex items-center space-x-2 p-1">
      {type === "success" ? (
        <HiCheckCircle className="text-2xl text-white" />
      ) : (
        <BiSolidMessageRoundedError className="text-2xl text-white" />
      )}
      <div className="text-white">
        <p className="text-xs">{message}</p>
      </div>
    </div>
    <button
      className="absolute bg-red-500 rounded-full -top-2 -right-1"
      onClick={() => setOpen()}
    >
      <RiCloseCircleFill className="text-white text-lg" />
    </button>
  </div>
);

export const NoData = ({ message }) => (
  <div className="flex w-full h-1/2 p-20  flex-col items-center justify-center">
    <img
      src="https://cdn2.iconfinder.com/data/icons/topaz-empty-state/48/Cloud-256.png"
      className="w-20 h-20 object-contain"
      alt="No Data"
    />
    <p className="text-lg text-red-500 whitespace-nowrap">{message}</p>
  </div>
);

export const PortalLocked = ({ logo, banner }) => (
  <motion.div
    initial={{ y: 100 }}
    animate={{ y: 0 }}
    exit={{ y: 100 }}
    style={{ backgroundImage: `url(${banner})` }}
    className="flex-1 h-screen flex items-center bg-cover justify-center md:p-20"
  >
    <div className="backdrop-blur-md w-full flex items-center justify-center h-screen">
      <div className="w-[80%] md:w-1/2 h-96 rounded-lg flex items-center justify-center flex-col bg-red-500/100">
        <div className="relative">
          <img
            src="https://cdn1.iconfinder.com/data/icons/bam-free-3d/128/Lock-locked.png"
            alt="Locked"
            className="w-48 h-48"
          />
          <img
            src={logo}
            alt="School"
            className="absolute bottom-4 right-4 w-20 h-20 object-contain"
          />

        </div>
        <h3 className="text-white text-3xl leading-relaxed">Sorry...</h3>
        <p className="text-sm text-gray-300 leading-loose">
          Portal has been Locked by Administrator
        </p>
          <button onClick={() => window.location.reload()}  className="w-3/4 mt-3 p-2 rounded-md bg-yellow-500 ">Check Again</button>
      </div>
    </div>
  </motion.div>
);

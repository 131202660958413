import { TopNav } from "./topnav";
import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { readDocument, updateData, dbName, proxy} from "../endpoints";
import { getDocument } from "../endpoints/fb";
import { schoolID } from "../../functions";
import { AnimatePresence, motion } from "framer-motion";
import { messaging } from "../endpoints/init";
import { getToken } from "firebase/messaging";
import { PortalLocked } from "../misc/feedback";

export default function Layout({ Node }) {
  let student = useMemo(() => JSON.parse(localStorage.getItem("student")),[])
  let isPortalOpen = useMemo(() =>localStorage.getItem("portalState") , [])
  const [fees, setFees] = useState([]);
  const [settings, setSettings] = useState({});
  const [sch, setSch] = useState(null);

  const IS_PRE_SCHOOL = student?.current_class[0] !== "B"
  const table = IS_PRE_SCHOOL ? "progress" : "exams"
  useEffect(() => {
    
    try {
      readDocument({
        path: "/portal",
        id: "standard",
        getData: (data) => {
          localStorage.setItem("portalState", data?.isStudentPortalOpen)
          if (data?.isStudentPortalOpen) {

            readDocument({
              path: "/student/fees/history",
              id: student?.student_id,
              getData: setFees,
            });

            readDocument({
              path: "/settings",
              id: "standard",
              getData: setSettings,
            });
          }

          Notification.requestPermission()
            .then((permission) => {
              if (permission === "granted") {
                getToken(messaging, {
                  vapidKey:
                    "BAcxVoEEf69lMnC8uIB1v3a6u8riz_L58FFT43Tm0n1N0QfMEvK_jFGCkeoBHIEl3s_79JrhncM-8a4tIQ889Fc",
                })
                  .then((currentToken) => {
                    if (currentToken) {
                      // send this to the alerts collections.
                      updateData({
                        path: "/messages/token",
                        id: "n-a",
                        data: {
                          id: localStorage.getItem("studentID"),
                          role: "Parent",
                          token: currentToken,
                        },
                      });
                      // update alerts collections. if
                    } else {
                      // Request Permission
                      console.log(
                        "No registration token available. Request permission to generate one."
                      );
                    }
                  })
                  .catch((err) => {
                    console.log(
                      "An error occurred while retrieving token. ",
                      err
                    );
                    // ...
                  });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        },
      });
    } catch (err) {
      console.log(err);
    }

    getDocument({
      path: "schoolx",
      id: schoolID,
      getData: setSch,
    });
  }, [student]);

  const data = {
    student,
    fees,
    settings,
    student_id: student?.student_id,
    sch,
    table, 
    IS_PRE_SCHOOL
  };

  const changeProfile = async (std) => {
    try{
      const { data, status } = await axios.get(
        `${proxy}/student/get-student/${std.student_id}`,
        { headers: { dbName, xmail: "pilot@logickids.com" } }
      );
      if (status === 200) {
        student = data
        localStorage.setItem("studentID", data.student_id)
        localStorage.setItem("student", JSON.stringify(data));
      }
    }catch(err){
      console.log(err);
    }
    
  };

  const current = {
    term: settings.currentTerm,
    year: settings.academicYear,
  };

  return (
    <AnimatePresence>
      {!isPortalOpen ? (
        <PortalLocked logo={sch?.logo} banner={sch?.banner} />
      ) : (
        <motion.div
          initial={{ y: 100 }}
          animate={{ y: 0 }}
          exit={{ y: 100 }}
          className="flex-1 m-0 md:mx-80 relative"
        >
          <TopNav
            student={student}
            changeProfile={changeProfile}
            current={current}
          />
          <div className="h-[98vh] overflow-y-auto bg-white">
            <Node {...data} />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

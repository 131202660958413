import logo from '../../assets/_.png'
import {ImSpinner2} from 'react-icons/im'

export const Loading = () => (
  <div className="flex  flex-col flex-1 w-full h-screen items-center justify-center bg-black">
    <div className="flex flex-col items-center justify-center">
      <img src={logo} alt="Logicians Ghana" className="w-[100px] h-[100px] object-contain" />
      <p className="text-yellow-500 text-sm text-center">Loading Data... Please Wait</p>
    </div>
      <div className="w-10 loader"></div>
  </div>
);

export const Spin = () => (
  <ImSpinner2 className="animate-spin" />
)

import { useEffect, useState } from "react";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { GoBell } from "react-icons/go";
import { MdOutlineCloseFullscreen } from "react-icons/md";
import { Sidebar } from "./sidebar";
import { Link, useNavigate } from "react-router-dom";
import { LuArrowUpRightFromCircle } from "react-icons/lu";
import { signOut } from "firebase/auth";
import { auth } from "../endpoints/init";
import { readDocument, uploadData } from "../endpoints";

export const TopNav = ({ student, changeProfile, current }) => {
  
  const [show, setShow] = useState(false);
  const [view, setView] = useState(false);
  const [messages, setMessages] = useState(0);
  const [siblings, setSiblings] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function findSiblings() {
      let phones = [student.contact_1, student.contact_2].filter(
        (ct) => parseInt(ct) !== 0 && !isNaN(parseInt(ct))
      );

      const { data } = await uploadData({
        path: "/student/siblings",
        data: {
          contact_1: phones[0],
          contact_2: phones.length > 1 ? phones[1] : phones[0],
        },
      });
      setSiblings(data);
    }

    readDocument({
      path: "/messages",
      id: `${current.term}?academic_year=${current.year}`,
      getData: (data) => setMessages(data?.length || 0),
    });

    findSiblings();
  }, [student.contact_1, student.contact_2, current.year, current.term]);

  // might prompt if exists on logging out
  const logout = () => {
    signOut(auth).then(() => {
      localStorage.clear();
      navigate("/login");
    });
  };

  const isActive = (id) => localStorage.getItem("studentID") === id;

  return (
    <div className="flex justify-between z-50 items-center w-full shadow-md bg-white p-4 sticky top-0">
      <div className="flex items-center space-x-2">
        <img
          src={student?.photo}
          alt={student.last_name}
          className="w-8 h-8 object-cover rounded-full"
        />
        <p>Welcome, {student?.last_name}👋</p>
      </div>
      <div className="flex gap-4 items-center">
        <Link to="/news" className="relative">
          <div className="w-4 h-4 rounded-full text-xs bg-red-500 text-white flex items-center justify-center absolute right-0 -top-1">
            {messages}
          </div>
          <GoBell className="text-3xl text-gray-500" />
        </Link>
        <button onClick={() => setView(!view)}>
          <img
            src={
              student?.photo ||
              "https://cdn2.iconfinder.com/data/icons/leto-blue-ui-generic-1/64/ui_-04-256.png"
            }
            alt={student.last_name}
            className="w-8 h-8 rounded-full object-contain border border-yellow-500"
          />
        </button>
        <button onClick={() => setShow(!show)}>
          {show ? (
            <MdOutlineCloseFullscreen className="text-3xl text-gray-500" />
          ) : (
            <HiOutlineMenuAlt2 className="text-3xl text-gray-500" />
          )}
        </button>
      </div>

      {view && (
        <div className="absolute top-16 right-5 w-60 h-auto bg-gray-200 shadow-md p-2">
          {/* Logout */}
          <div className="flex flex-wrap gap-3 border-b border-b-gray-400 p-2 ">
            {siblings?.map((sib, info) => (
              <button
                disabled={isActive(sib.student_id) || sib.is_active === false}
                onClick={() => changeProfile(sib)}
                className={`${
                  isActive(sib.student_id)
                    ? "border-4 border-blue-500"
                    : "border border-violet"
                } bg-white rounded-full`}
                key={info}
              >
                <img
                  src={sib?.photo}
                  alt={sib?.last_name}
                  className="w-10 h-10 object-contain shrink-0 rounded-full"
                />
              </button>
            ))}
          </div>
          <button
            className="flex gap-2 items-center p-2 text-red-500"
            onClick={logout}
          >
            <LuArrowUpRightFromCircle /> <p className="text-sm">Log out</p>
          </button>
        </div>
      )}

      <Sidebar setShow={setShow} show={show} />
    </div>
  );
};


import CryptoJS from "crypto-js";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
const encryptKey = 'x-constants'

export const encryptData = (text) => {
  const data = CryptoJS.AES.encrypt(
    JSON.stringify(text),
    encryptKey
  ).toString();

  return data;
};

export const decryptData = (text) => {
  try {
    const bytes = CryptoJS.AES.decrypt(text, encryptKey);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return data;
  } catch (error) {
    return null;
  }
};


export const formatMail = (id) => {
  const addr = id.replaceAll("-",".")

  return addr+"@lschoolx.com".toLowerCase()
}

export const schoolID = decryptData(localStorage.getItem("schoolID"))


export const sortResults = (arr) => {
  return arr
    .sort((a, b) => {
      return a - b;
    })
    .reverse();
};

export const getImage = async (url, exam) => {
  await fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const reader = new FileReader();
      reader.onload = () => {
        exam.photoURI = reader.result;
        //   this.setState({ data_image: reader.result });
      };
      reader.onerror = (err) => {
        console.log(err);
      };
      reader.readAsDataURL(blob);
    })
    .catch((err) => {
      console.log("Error: ", err);
    });
};

export const useQuery = () =>  {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
import { NavLink } from "react-router-dom";
import { VscPieChart } from "react-icons/vsc";
import { BsPersonCheck, BsCalendarDate } from "react-icons/bs";
import { AiOutlineBorderlessTable } from "react-icons/ai";
import { LiaBookSolid } from "react-icons/lia";
import { TbMoneybag } from "react-icons/tb";
import { HiOutlineUserGroup } from "react-icons/hi2";
// import { LuClipboardList } from "react-icons/lu";
import { motion } from "framer-motion";
import {
  HiOutlineNewspaper,
  HiArrowCircleLeft,
  HiArrowCircleRight,
} from "react-icons/hi";
import { PiUserCircleGear } from "react-icons/pi";
import { useState } from "react";

const Nav = ({ path, Icon, title, toggle, setShow }) => (
  <NavLink

    to={path}
    onClick={() => setShow(false)}
    className={({isActive}) => `flex ${isActive ? 'text-[#0091d5]  rounded-md' : 'text-gray-600'} items-center p-2 gap-2 cursor-pointer`}
  >
    <Icon className={`${toggle ? "text-2xl" : "text-xl"} `} />{" "}
    <p className={`${toggle ? "hidden" : "inline"}`}>{title}</p>
  </NavLink>
);
export const Sidebar = ({ show, setShow }) => {
  const [toggle, setToggle] = useState(false);
  return (
    <>
      {show && (
        <motion.div
          transition={{
            duration: 0.5,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01],
          }}
          initial={{ x: -20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -20, opacity: 0 }}
          className={`flex flex-col h-[98vh] z-50 gap-5 md:gap-4 absolute left-0  top-16 bg-white shadow-lg border-r border-r-gray-500 ${
            toggle ? "items-center w-20" : "w-1/2"
          }`}
        >
          <>
            <Nav
              toggle={toggle}
              setShow={setShow}
              path={"/"}
              title={"Dashboard"}
              Icon={VscPieChart}
            />
            <Nav
              toggle={toggle}
              setShow={setShow}
              path={"/attendance"}
              title={"Attendance"}
              Icon={BsPersonCheck}
            />
            <Nav
              toggle={toggle}
              setShow={setShow}
              path={"/time-table"}
              title={"Time Table"}
              Icon={AiOutlineBorderlessTable}
            />
            <Nav
              toggle={toggle}
              setShow={setShow}
              path={"/assignments"}
              title={"Homework"}
              Icon={LiaBookSolid}
            />
            <Nav
              toggle={toggle}
              setShow={setShow}
              path={"/calendar"}
              title={"Calendar"}
              Icon={BsCalendarDate}
            />
            <Nav
              toggle={toggle}
              setShow={setShow}
              path={"/fees"}
              title={"Fees"}
              Icon={TbMoneybag}
            />
            <Nav
              toggle={toggle}
              setShow={setShow}
              path={"/classmates"}
              title={"Classmates"}
              Icon={HiOutlineUserGroup}
            />
            <Nav
              toggle={toggle}
              setShow={setShow}
              path={"/news"}
              title={"News"}
              Icon={HiOutlineNewspaper}
            />
            <Nav
              toggle={toggle}
              setShow={setShow}
              path={"/profile"}
              title={"Profile"}
              Icon={PiUserCircleGear}
            />

            <button
              className="absolute top-[80vh] -right-2 z-50"
              onClick={() => setToggle(!toggle)}
            >
              {toggle ? (
                <HiArrowCircleRight className="text-lg" />
              ) : (
                <HiArrowCircleLeft className="text-lg" />
              )}
            </button>
          </>
        </motion.div>
      )}
    </>
  );
};

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Suspense, lazy } from "react";
import Layout from "./components/layout";

import { Loading } from "./components/loader";


const Classmates =  lazy(() => import("./pages/Classmates"));
const Login = lazy(() => import("./pages/Login"));
const Register = lazy(() => import("./pages/Register"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Fees = lazy(() => import("./pages/Fees"));
const News = lazy(() => import("./pages/News"));
const Attendance = lazy(() => import("./pages/Attendance"));
const TimeTable = lazy(() => import("./pages/Timetable"));
const Outline = lazy(() => import("./pages/Outline"));
const Profile = lazy(() => import("./pages/Profile"));
const Todo = lazy(() => import("./pages/Todo"));
const Assignments = lazy(() => import("./pages/Assignments"));
const Reset = lazy(() => import("./pages/Reset"))

// Private Routes
const Private = ({ node }) => {
  const authenticated = localStorage.getItem("STDLOGGED")
  return authenticated ? (
    <Layout Node={node} />
  ) : (
    <Navigate to={"/login"} replace />
  );
};

function App() {
  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <Routes>
          <Route path="/" element={<Private node={Dashboard} />} />
          <Route path="/fees" element={<Private node={Fees} />} />
          <Route path="/news" element={<Private node={News} />} />
          <Route path="/attendance" element={<Private node={Attendance} />} />
          <Route path="/time-table" element={<Private node={TimeTable} />} />
          <Route path="/assignments" element={<Private node={Assignments} />} />
          <Route path="/calendar" element={<Private node={Outline} />} />
          <Route path="/profile" element={<Private node={Profile} />} />
          <Route path="/classmates" element={<Private  node={Classmates} />} />
          <Route path="/todos" element={<Private node={Todo} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/reset-password" element={<Reset />} />
        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
